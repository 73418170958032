import React, { useEffect, useRef, useState } from 'react';
import TouchEvents from 'react-touch-events';

import jsonData from '../data/AccuPointsLocation.json'; // Import the JSON file directly


const ImageMarker = ({ imageUrl }) => {
  const [points, setPoints] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [inputNames, setInputNames] = useState('');
  const imageRef = useRef(null);

  useEffect(() => {
   
        if (jsonData.coordinates) {
          setCoordinates(jsonData.coordinates);
        }
  });

  const getRelativeCoordinates = (absoluteX, absoluteY, rect) => {
    const x = ((absoluteX - rect.left) / rect.width) * 100;
    const y = ((absoluteY - rect.top) / rect.height) * 100;
    return { x, y };
  };



  const handleEvent = (e) => {
    if (e.type === 'click' || (e.type === 'keydown' && e.key === 'Enter')) {
      const rect = imageRef.current.getBoundingClientRect();
      const x = ((e.clientX || e.pageX) - rect.left) / rect.width * 100;
      const y = ((e.clientY || e.pageY) - rect.top) / rect.height * 100;
      setPoints([...points, { x, y }]);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => handleEvent(e);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [points]);




  const [showTooltip, setShowTooltip] = useState(true);

  const handleClick = (e) => {
    {/*imageRef = imageType === 'front' ? frontImageRef.current : backImageRef.current;*/}
    const rect = imageRef.current.getBoundingClientRect();
    const { x, y } = getRelativeCoordinates(e.clientX, e.clientY, rect);
    setPoints([...points, { x, y }]);
  };

  const handleClick_left = (e) => {
    {/*imageRef = imageType === 'back' ? frontImageRef.current : backImageRef.current;*/}
    const rect = imageRef.current.getBoundingClientRect();
    const { x, y } = getRelativeCoordinates(e.clientX, e.clientY, rect);
    setPoints([...points, { x, y }]);
  };

  const handleTouch = (e) => {
    const rect = imageRef.current.getBoundingClientRect();
    const touch = e.changedTouches[0];
    const { x, y } = getRelativeCoordinates(touch.clientX, touch.clientY, rect);
    setPoints([...points, { x, y }]);
  };

  const handleInput = () => {
    const names = inputNames.split(',').map(name => name.trim());
    
    
    const selectedPoints = coordinates.filter(coord => names.includes(coord.name));
    setPoints(selectedPoints.map(coord => ({name: coord.name, x: coord.x, y: coord.y ,color: coord.color, img: coord.img, palmSide: coord.side })));
  
    
  };


  
  return (
    <div>

<div>
<label htmlFor="points">Enter Points</label>
        <input
          type="text"
          value={inputNames.toUpperCase()}
          onChange={(e) => setInputNames(e.target.value.toUpperCase())}
          style={{width: "470px"}}
          placeholder="e.g., G1, G5 (comma separated)"
        />


          
        <button onClick={handleInput}>Display Points</button>
      </div>

      <TouchEvents>
      <table>
              <tr>
                <td>
                  <div className="image-block"> {/*onClick={() => handleImageClick('front')}>*/}
                    <div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>



          <img
            ref={imageRef}
            src="/LeftHandFront-WithoutMeridian_new.png"
            alt="Markable"
      
            style={{ maxWidth: '100%', height: 'auto' }}
            
          />
         
          {points.map((point, index) => ( (point.palmSide=="front"||point.palmSide==null)&& (
            
            <div
              key={index}
              style={{
                position: 'absolute',
                top: `${point.y}%`,
                left: `${point.x}%`,
                width: '10px',
                height: '10px',
                backgroundColor: point.color==null?"black":point.color,
                borderRadius: '50%',
                transform: 'translate(-50%, -50%)',
              }}


              
            >
            
{showTooltip && point.name!=null && (
        <div style={{
          position: 'absolute',
          bottom: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          marginBottom: '1px',
          padding: '1px 2px',
       
       
          color: 'rgba(0, 0, 0, 0.7)', // Transparent black text
          borderRadius: '1px',
          whiteSpace: 'nowrap',
         
          zIndex: 1
        }}>
          {point.name} 
        </div>
      )}
              </div>

            
          )))}






</div>
</div>
</td>

<td>
<div className="image-block"> {/*onClick={() => handleImageClick('front')}>*/}
                    <div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>


      
          <img
            ref={imageRef}
            src="/LeftHandBack-WithoutMeridian.png"
            alt="Markable"
 
            style={{ maxWidth: '100%', height: 'auto' }}
            
          />
 
          {points.map((point, index) => ( (point.palmSide=="back" ||  point.palmSide==null) && (
            
            <div
              key={index}
              style={{
                position: 'absolute',
                top: `${point.y}%`,
                left: `${point.x}%`,
                width: '10px',
                height: '10px',
                backgroundColor: point.color==null?"black":point.color,
                borderRadius: '50%',
                transform: 'translate(-50%, -50%)',
              }}


              
            >
            
{showTooltip && point.name!=null && (
        <div style={{
          position: 'absolute',
          bottom: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          marginBottom: '1px',
          padding: '1px 2px',
         
          color: 'rgba(0, 0, 0, 0.7)', // Transparent black text
          borderRadius: '1px',
          whiteSpace: 'nowrap',
          zIndex: 1
        }}>
          {point.name} 
        </div>
      )}
              </div>

            
          )))}



        


        
        </div>
</div>
 </td>
</tr>
</table>     
      </TouchEvents>
   
      <p>
        { /*{points.map((point, index) => `{ "name": "pointName", "x":${point.x.toFixed(2)}, "y": ${point.y.toFixed(2)} , "color":"yellow"}`).join(', ')}
          */}
       

        </p>
    </div>
  );
};

export default ImageMarker;
