import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './MarkPoints.css';
import jsonData from '../data/AccuPointsLocation.json'; // Import the JSON file directly
import data from '../data/diseases_points_mapping.json';

const MarkPoints = () => {
  const pointsMapping = jsonData.coordinates.reduce((acc, point) => {
    acc[point.name] = {
      x: point.x,
      y: point.y,
      side: point.side, 
      color: point.color 
    };
    // Return the updated accumulator for the next iteration
    return acc;
  }, {});


  const [healingSystem, setHealingSystem] = useState('');
  const [points, setPoints] = useState('');
  
  const [showResults, setShowResults] = useState(false);
  const [mappedPoints, setMappedPoints] = useState([]);
  const [frontImageDimensions, setFrontImageDimensions] = useState({ width: 0, height: 0 });
  const [backImageDimensions, setBackImageDimensions] = useState({ width: 0, height: 0 });
  const frontImageRef = useRef(null);
  const backImageRef = useRef(null);
  const navigate = useNavigate();
  const imageRef = useRef(null);


  const handleClick = (e) => {
    const rect = imageRef.current.getBoundingClientRect();
    const { x, y } = getRelativeCoordinates(e.clientX, e.clientY, rect);
    setPoints([...points, { x, y }]);
  };
  
  const getRelativeCoordinates = (absoluteX, absoluteY, rect) => {
    const x = ((absoluteX - rect.left) / rect.width) * 100;
    const y = ((absoluteY - rect.top) / rect.height) * 100;
    return { x, y };
  };



  useEffect(() => {
    if (frontImageRef.current) {
      const handleFrontImageLoad = () => {
        setFrontImageDimensions({
          width: frontImageRef.current.width,
          height: frontImageRef.current.height,
        });
      };
      frontImageRef.current.onload = handleFrontImageLoad;
      handleFrontImageLoad(); // Call it immediately in case the image is already loaded
    }
  }, [showResults]);

  useEffect(() => {
    if (backImageRef.current) {
      const handleBackImageLoad = () => {
        setBackImageDimensions({
          width: backImageRef.current.width,
          height: backImageRef.current.height,
        });
      };
      backImageRef.current.onload = handleBackImageLoad;
      handleBackImageLoad(); // Call it immediately in case the image is already loaded
    }
  }, [showResults]);

  const handleShowPoints = () => {
    if (healingSystem && points) {
      const pointsArray = points.split(',').map(point => point.trim());
      const validPoints = pointsArray.filter(point => pointsMapping[point]);
      
      setMappedPoints(validPoints);
      setShowResults(true);


      //navigate(`/image-page`, { state: { imageType: 'front', points: validPoints } });
    }
  };


  const handleShowPoints1 = () => {
    if (healingSystem && inputValue) {
      const pointsArray = inputValue.split(',').map(point => point.trim());
      const validPoints = pointsArray.filter(point => pointsMapping[point]);
      setMappedPoints(validPoints);
      setShowResults(true);


      const names = inputNames.split(',').map(name => name.trim());
    
      
      const selectedPoints = coordinates.filter(coord => names.includes(coord.name));
      setPoints(selectedPoints.map(coord => ({ x: coord.x, y: coord.y ,color: coord.color, img: coord.img })));
      
      
      //navigate(`/image-page`, { state: { imageType: 'front', points: validPoints } });
    }
  };


  const handleEdit = () => {
    setShowResults(false);
    setHealingSystem('');
    setPoints('');
  };

  const handleImageClick = (imageType) => {
    navigate(`/image-page/${imageType}`);
  };
  const [showTooltip, setShowTooltip] = useState(true);

  
  const renderPoints = (imageType) => {
    const imageRef = imageType === 'front' ? frontImageRef.current : backImageRef.current;


    if (!imageRef) return null;

    return mappedPoints
      .filter(point => pointsMapping[point].side === imageType)
      .map((point, index) => {
        const { x, y, color } = pointsMapping[point];
        // Calculate positions based on image dimensions

        return (
          <div
            key={index}
            
            style={{
              
              top: `${y}%`,
              left: `${x}%`,

                position: 'absolute',
                width: '10px',
                height: '10px',
                backgroundColor: color==null?"black":color,
                borderRadius: '50%',
                transform: 'translate(-50%, -50%)',
                
              

            }}
          >

{showTooltip && (
        <div style={{
          position: 'absolute',
          bottom: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
          marginBottom: '5px',
          padding: '5px 10px',
          backgroundColor: 'red',
          color: 'white',
          borderRadius: '4px',
          whiteSpace: 'nowrap',
          zIndex: 1
        }}>
          {point}
        </div>
      )}

          </div>
        );
      });
  };

  const handleSaveImage = () => {
    const canvas = document.createElement('canvas');
    canvas.width = frontImageRef.current.width;
    canvas.height = frontImageRef.current.height;
    const ctx = canvas.getContext('2d');

    // Draw the image
    ctx.drawImage(frontImageRef.current, 0, 0);

    // Draw the points
    mappedPoints.forEach(point => {
      const { x, y } = pointsMapping[point];
      ctx.beginPath();
      ctx.arc(x, y, 5, 0, 2 * Math.PI);
      ctx.fillStyle = 'yellow';
      ctx.fill();
    });

    // Convert the canvas to a data URL
    const dataUrl = canvas.toDataURL('image/png');

    // Create a temporary anchor element to trigger the download
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = 'marked_image.png';
    link.click();
  };



  const [selectedKey, setSelectedKey] = useState('');
  const [values, setValues] = useState([]);

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (selectedKey && data[selectedKey]) {
      setValues(data[selectedKey]);
      setInputValue(data[selectedKey].join(', ')); // Store the values in a variable and set it to the input box
    } else {
      setValues([]);
      setInputValue(''); // Clear the input box if no key is selected
    }
  }, [selectedKey]);

  const handleSelectChange = (event) => {
    setSelectedKey(event.target.value);
    setHealingSystem(event.target.value);
  };





  const [searchTerm, setSearchTerm] = useState('');

  const [filteredKeys, setFilteredKeys] = useState(Object.keys(data));

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    setFilteredKeys(Object.keys(data).filter(key => key.toLowerCase().includes(term.toLowerCase())));
  };

  const handleSelectKey = (key) => {
    setSelectedKey(key);
    setValues(data[key]);
    setInputValue(data[key].join(', '));
    setSearchTerm(key);
    setSelectedKey(key);
    setHealingSystem(data[key]);
  };

  const [coordinates, setCoordinates] = useState([]);

  const [inputNames, setInputNames] = useState('');
  
  {/*useEffect(() => {
    // Fetch coordinates from JSON file
    fetch('/AccuPointsLocation.json')
      .then(response => response.json())
      .then(data => {
        if (data.coordinates) {
          setCoordinates(data.coordinates);
        }
      })
      .catch(error => console.error('Error fetching coordinates:', error));
  }, []); */}


  
  

  const handleInput = () => {
    const names = inputNames.split(',').map(name => name.trim());
    
    
    const selectedPoints = coordinates.filter(coord => names.includes(coord.name));
    setPoints(selectedPoints.map(coord => ({ x: coord.x, y: coord.y ,color: coord.color, img: coord.img })));
  };








  return (
    <div className="MarkPoints">














      {!showResults ? (
        <>




          <h1>Search and Select a disease</h1>

          <input
            type="text"
            placeholder="Search problem..."
            value={searchTerm}
            onChange={handleSearchChange}
            onCutCapture={handleSearchChange}
            style={{width: "470px"}}
          />
          {searchTerm && (
            <div className="search-results">
              {filteredKeys.map((key) => (
                <div
                  value={key}
                  className="search-result-item"
                  onClick={() => handleSelectKey(key)}
                  onChange={handleSelectChange}
                  
                >
                  {key.charAt(0).toUpperCase() + key.slice(1)}   
                </div>
              ))}



            </div>






          )}

          {values.length > 0 && (

            <div>
              <button onClick={handleShowPoints1}>Show Points</button>
           
              <p>
                <h2>Points for {selectedKey.charAt(0).toUpperCase() + selectedKey.slice(1)}</h2>
                <input
                  type="hidden"
                  id="points"
                  value={inputValue}
                  
                  onChange={(e) => setInputValue(e.target.value)}

                />
              </p>

              <p>
                {inputValue}
              </p>
            </div>

          )}








        </>
      ) : (
        <div className="results">
          <h2>Your Points</h2>
          <p>{points}</p>
          <button onClick={handleEdit}>Edit</button>

          <div className="image-blocks">


            <table>
              <tr>
                <td>
                  <div className="image-block"> {/*onClick={() => handleImageClick('front')}>*/}
                    <div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
                      <img ref={frontImageRef} src="/LeftHandFront-WithoutMeridian_new.png" alt="Front Hand" style={{ maxWidth: '100%', height: 'auto' }} 

                    
                      />
                      {renderPoints('front')}
                      
             

                      {points.map((point, index) => (
            
            <div
              key={index}
              style={{
                position: 'absolute',
                top: `${point.y}%`,
                left: `${point.x}%`,
                width: '10px',
                height: '10px',
                backgroundColor: point.color==null?"black":point.color,
                borderRadius: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
            
          ))}





                    </div>

                    <p>Front Hand</p>
                  </div>
                </td>

                <td>
                  <div className="image-block"> {/*onClick={() => handleImageClick('back')}>*/}
                    <div className="image-container" style={{ position: 'relative', display: 'inline-block' }}>
                      <img ref={backImageRef} src="/LeftHandBack-WithoutMeridian.png" alt="Back Hand" style={{ maxWidth: '100%', height: 'auto' }} />
                      {renderPoints('back')}
                    </div>
                    <p>Back Hand</p>
                  </div>
                </td>
              </tr>
              
            </table>

          </div>
        </div>
      )}



    </div>



  );
};

export default MarkPoints;
