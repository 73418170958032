import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import BottomMenu from './components/BottomMenu';
import Home from './pages/Home';
import OtherPage from './pages/OtherPage';
import MarkPoints from './pages/MarkPoints';
import SearchPoints from './pages/SearchPoints';
import ImagePage from './pages/ImagePage';
import PointMarker4Admin from './pages/pointMarker4Admin';
import './App.css';
import Navbar from "./components/Navbar";





const App = () => {




  return (
  
  <Router>

      <Navbar />
  
      <div className="main-layout">
      <center>
        <main className="content">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/other-page" element={<OtherPage />} />
            <Route path="/mark-points" element={<MarkPoints />} />
            <Route path="/search-points" element={<SearchPoints />} />
            <Route path="/image-page/:imageType" element={<ImagePage />} />
            <Route path="/pointMarker4Admin" element={<PointMarker4Admin />} />

            
          </Routes>
        </main>
        </center>
      </div>
     {<BottomMenu/>}
    </Router>
  );
};

export default App;
