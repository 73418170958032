import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../Styles/main.css";
import logo_dark from "../Assets/logo.png";
import Home from '../pages/Home';
import OtherPage from '../pages/OtherPage';
import MarkPoints from '../pages/MarkPoints';
import SearchPoints from '../pages/SearchPoints';
import ImagePage from '../pages/ImagePage';

import pointMarker4Admin from '../pages/pointMarker4Admin';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function Navbar() {
	const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};

	return (
		<header>
            <img src={logo_dark} alt="accure" />
			<nav ref={navRef}>
				<a href="/">Home</a>
				<a href="/mark-points">Show Points</a>
				<a href="/search-points">SearchPoints</a>
				<a href="/pointMarker4Admin">PointLocationFinder</a>
				
				<a href="/#">About me</a>



				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button>
			</nav>
			<button
				className="nav-btn"
				onClick={showNavbar}>
				<FaBars />
			</button>
		</header>
	);
}

export default Navbar;