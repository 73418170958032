import React, { useEffect, useRef, useState } from 'react';
import TouchEvents from 'react-touch-events';

const PointMarker4Admin = ({ imageUrl }) => {
  const [points, setPoints] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [inputNames, setInputNames] = useState('');
  const imageRef = useRef(null);

  
  useEffect(() => {
    // Fetch coordinates from JSON file
    fetch('/AccuPointsLocation.json')
      .then(response => response.json())
      .then(data => {
        if (data.coordinates) {
          setCoordinates(data.coordinates);
        }
      })
      .catch(error => console.error('Error fetching coordinates:', error));
  }, []);

  const getRelativeCoordinates = (absoluteX, absoluteY, rect) => {
    const x = ((absoluteX - rect.left) / rect.width) * 100;
    const y = ((absoluteY - rect.top) / rect.height) * 100;
    return { x, y };
  };



  const handleEvent = (e) => {
    if (e.type === 'click' || (e.type === 'keydown' && e.key === 'Enter')) {
      const rect = imageRef.current.getBoundingClientRect();
      const x = ((e.clientX || e.pageX) - rect.left) / rect.width * 100;
      const y = ((e.clientY || e.pageY) - rect.top) / rect.height * 100;
      setPoints([...points, { x, y }]);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => handleEvent(e);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [points]);






  const handleClick = (e) => {
    const rect = imageRef.current.getBoundingClientRect();
    const { x, y } = getRelativeCoordinates(e.clientX, e.clientY, rect);
    setPoints([...points, { x, y }]);
  };

  const handleTouch = (e) => {
    const rect = imageRef.current.getBoundingClientRect();
    const touch = e.changedTouches[0];
    const { x, y } = getRelativeCoordinates(touch.clientX, touch.clientY, rect);
    setPoints([...points, { x, y }]);
  };

  const handleInput = () => {
    const names = inputNames.split(',').map(name => name.trim());
    
    
    const selectedPoints = coordinates.filter(coord => names.includes(coord.name));
    setPoints(selectedPoints.map(coord => ({ x: coord.x, y: coord.y ,color: coord.color, img: coord.img })));
  };
  const [selectedValue, setSelectedValue] = useState("PointMarked/CV-palmAsset 7xhdpi.png");

  const values = ["PointMarked/CV-palmAsset 7xhdpi.png", "PointMarked/GB-backAsset 12xhdpi.png", "PointMarked/GB-palmAsset 11xhdpi.png", "PointMarked/GV-backAsset 3xhdpi.png", "PointMarked/GV-palmAsset 2xhdpi.png", "PointMarked/H-palmAsset 8xhdpi.png", "PointMarked/Kidney-palmAsset 4xhdpi.png", "PointMarked/L-palmAsset 16xhdpi.png", "PointMarked/Li-backAsset 15xhdpi.png", "PointMarked/Li-palmAsset 14xhdpi.png", "PointMarked/Lu-palmAsset 13xhdpi.png", "PointMarked/P-palmAsset 20xhdpi.png", "PointMarked/Si-backAsset 22xhdpi.png", "PointMarked/Si-palmAsset 21xhdpi.png", "PointMarked/Sp-palmAsset 23xhdpi.png", "PointMarked/St-backAsset 26xhdpi.png", "PointMarked/St-palmAsset 24xhdpi.png", "PointMarked/TW-backAsset 6xhdpi.png", "PointMarked/TW-palmAsset 5xhdpi.png", "PointMarked/UB-backAsset 10xhdpi.png", "PointMarked/UB-palmAsset 9xhdpi.png"];
  

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <div>

<div>

<label htmlFor="dropdown">Choose an option:</label>
      <select id="dropdown" value={selectedValue} onChange={handleChange}>
        <option value="" disabled>Select an option</option>
        {values.map((value, index) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))}
      </select>

          
        <button onClick={handleInput}>Clear Points</button>
      </div>
      <table>
      <tr>
      <td>
    <TouchEvents>
       
        <div style={{ position: 'relative', display: 'inline-block' }}> 
          <img
            ref={imageRef}
            src={selectedValue}
            alt="Markable"
            onClick={handleClick}
            style={{ maxWidth: '100%', height: 'auto' }}
            
          />
          
          {points.map((point, index) => (
            
            <div
              key={index}
              style={{
                position: 'absolute',
                top: `${point.y}%`,
                left: `${point.x}%`,
                width: '10px',
                height: '10px',
                backgroundColor: point.color==null?"black":point.color,
                borderRadius: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
            
          ))}

        </div>
     
      </TouchEvents>
   </td>
   </tr>
   <tr>
   <td>
      <p>
       {points.map((point, index) => `{ "name": "pointName", "x":${point.x.toFixed(2)}, "y": ${point.y.toFixed(2)} , "color":"yellow"}`).join(', ')}
       </p>
  
 
</td>
 </tr>
 <tr>
   <td>
      <p>
      hello
       </p>
  
 
</td>
 </tr>
 <tr>
   <td>
      <p>
      Hello
       </p>
  
 
</td>
 </tr>
 <tr>
   <td>
      <p>
            
       </p>
  
 
</td>
 </tr>
 <tr>
   <td>
      <p>
           
       </p>
  
 
</td>
 </tr>
 <tr>
   <td>
      <p>
            
       </p>
  
 
</td>
 </tr>
 
</table>
        
    </div>
  );
};

export default PointMarker4Admin;
