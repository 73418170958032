import React, { useState } from 'react';
import ImageMarker from '../pages/ImageMarker.js';


const SearchPoints = () => {

  

 



  return (
    <div>
    <h1>Accupressure Point Locator</h1>
 
    <ImageMarker imageUrl="LeftHandFront-WithOutMeridian-new.png"  />
    </div>


  );
};

export default SearchPoints;
